import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const NewResultButton = () => {
    return (
        <div className='leaderboard__new-results-button'>
            <Button variant="fab" color="primary" aria-label="Add"  type="button" data-toggle="modal" data-target="#addResultModal">
                <AddIcon />
            </Button>
        </div>
    );
};


export default NewResultButton;