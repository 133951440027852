import React from 'react';
import './AddLeagueModal.css';

const AddLeagueModal = () => {
    return (
        <div className="container add-league-modal">
            <div className="modal fade add-league-modal__container" id="addLeagueModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Leaderboards</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn btn-block btn-lg btn-success add-league-modal__button"
                                variant="fab" color="primary" aria-label="Add" data-toggle="modal" data-target="#createNewLeagueModal"
                                data-dismiss="modal">Create a new league</button>
                            <button type="button" className="btn btn-block btn-lg btn-success add-league-modal__button"
                                variant="fab" color="primary" aria-label="Add" data-toggle="modal" data-target="#joinExistingLeagueModal"
                                data-dismiss="modal">Join an existing league</button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddLeagueModal;