// authActions.js

import { auth } from "../../config/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
} from "firebase/auth";
import { addUserToBackend } from "./backendIntegration";
import { getUserData } from "./ActionUtils";

// Action Types
export const LOADING_START = "LOADING_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_EMAIL_SUCCESS = "REGISTER_EMAIL_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const LOGIN_GOOGLE_SUCCESS = "LOGIN_GOOGLE_SUCCESS";
export const LOGIN_FACEBOOK_SUCCESS = "LOGIN_FACEBOOK_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const SET_LOGGED_IN_USER_SUCCESS = "SET_LOGGED_IN_USER_SUCCESS";
export const SET_LOGGED_IN_USER_PENDING = "SET_LOGGED_IN_USER_PENDING";
export const SET_LOGGED_IN_USER_ERROR = "SET_LOGGED_IN_USER_ERROR";
export const START_INITIAL_LOAD = "START_INITIAL_LOAD";
export const START_LOGGED_OUT = "START_LOGGED_OUT";

// Action Creators

export const loginWithEmail = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_START });
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      dispatch({ type: LOGIN_SUCCESS });
      const firebaseUser = userCredential.user;
      await dispatch(setLoggedInUser(firebaseUser));
    } catch (error) {
      dispatch({ type: LOGIN_ERROR, error });
    }
  };
};

export const register = (email, password, displayName, displayPictureURL) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_START });
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;

      // Add user to backend
      await addUserToBackend(
        firebaseUser.uid,
        displayName,
        displayPictureURL,
        email
      );

      // Dispatch "REGISTER_EMAIL_SUCCESS"
      dispatch({ type: REGISTER_EMAIL_SUCCESS });

      // Fetch and set logged-in user data
      await dispatch(setLoggedInUser(firebaseUser));
    } catch (error) {
      dispatch({ type: REGISTER_ERROR, error });
    }
  };
};

export const loginWithGoogle = () => {
  return async (dispatch) => {
    dispatch({ type: LOADING_START });
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      dispatch({ type: LOGIN_GOOGLE_SUCCESS });
      const firebaseUser = userCredential.user;
      await dispatch(setLoggedInUser(firebaseUser));
    } catch (error) {
      dispatch({ type: LOGIN_ERROR, error });
    }
  };
};

export const loginWithFacebook = () => {
  return async (dispatch) => {
    dispatch({ type: LOADING_START });
    const provider = new FacebookAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      dispatch({ type: LOGIN_FACEBOOK_SUCCESS });
      const firebaseUser = userCredential.user;
      await dispatch(setLoggedInUser(firebaseUser));
    } catch (error) {
      dispatch({ type: LOGIN_ERROR, error });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await signOut(auth);
      dispatch({ type: LOGOUT });
      dispatch({ type: START_LOGGED_OUT }); // Ensure loading state is cleared
    } catch (error) {
      console.error("Sign Out Error", error);
      dispatch({ type: LOGOUT_ERROR, error });
    }
  };
};

export const setLoggedInUser = (firebaseUser) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_START });
    try {
      // Try to fetch user data from backend
      let userData = await getUserData(firebaseUser.uid, true);
      if (!userData || !userData.user) {
        // User not found, add user to backend
        await addUserToBackend(
          firebaseUser.uid,
          firebaseUser.displayName || "Unknown User",
          firebaseUser.photoURL || "",
          firebaseUser.email || ""
        );
        // Fetch the user data again
        userData = await getUserData(firebaseUser.uid, true);
      }

      if (userData && userData.user) {
        dispatch({
          type: SET_LOGGED_IN_USER_SUCCESS,
          user: userData.user,
          leagues: userData.leagues,
        });
      } else {
        // Still no user data after adding user to backend
        dispatch({
          type: SET_LOGGED_IN_USER_ERROR,
          error: {
            message: "Failed to fetch user data after adding user to backend.",
          },
        });
      }
    } catch (error) {
      dispatch({ type: SET_LOGGED_IN_USER_ERROR, error });
    }
  };
};

export const startInitialLoad = () => {
  return { type: START_INITIAL_LOAD };
};

export const startLoggedIn = (user) => {
  return async (dispatch) => {
    try {
      await dispatch(setLoggedInUser(user));
    } catch (error) {
      console.error("Error in startLoggedIn:", error);
      dispatch({ type: START_LOGGED_OUT });
    }
  };
};

export const startLoggedOut = () => {
  return { type: START_LOGGED_OUT };
};
