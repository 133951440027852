import auth from "./authReducer";
import leagues from "./leaguesReducer";
import loading from "./loadingReducer";
import routes from "./routesReducer";
import selectedLeague from "./selectedLeagueReducer";
import user from "./userReducer";
import { combineReducers } from "redux";

const appReducer = combineReducers({
  auth,
  leagues,
  loading,
  routes,
  selectedLeague,
  user,
});

export default (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
