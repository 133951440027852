import React from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

const EditLeagueButton = () => {
    return (
        <div className='leaderboard__edit-league-button'>
            <Button variant="fab" color="primary" aria-label="Add"  type="button" data-toggle="modal" data-target="#editLeagueModal">
                <EditIcon />
            </Button>
        </div>
    );
};


export default EditLeagueButton;