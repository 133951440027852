import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ShareIcon from "@material-ui/icons/Share";
import NewResultButton from "./NewResultButton";
import EditLeagueButton from "./EditLeagueButton";
import AddResultModal from "../modals/AddResultModal";
import EditLeagueModal from "../modals/EditLeagueModal";
import CopyLeagueIdModal from "../modals/CopyLeagueIdModal";
import Table from "./Table";
import "./League.css";
import { selectLeague } from "../../store/actions/leagueActions";

const League = () => {
  const { leagueId } = useParams();
  const dispatch = useDispatch();
  const selectedLeague = useSelector((state) => state.selectedLeague);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (leagueId && (!selectedLeague || selectedLeague.id !== leagueId)) {
      dispatch(selectLeague(leagueId));
    }
  }, [leagueId, selectedLeague, dispatch]);

  if (!selectedLeague) {
    return <div>Loading league...</div>;
  }

  const sortedUsers = selectedLeague.users
    ? [...selectedLeague.users].sort((a, b) => {
        if (a.displayName < b.displayName) return -1;
        if (a.displayName > b.displayName) return 1;
        return 0;
      })
    : [];

  return (
    <div className="leaderboard">
      <div className="leaderboard__header">
        <div className="leaderboard__header-title">
          <span className="leaderboard__header-empty" />
          <span>{selectedLeague.leagueName}</span>
          <span
            className="leaderboard__header-icon"
            data-toggle="modal"
            data-target="#copyLeagueIdModal"
          >
            <ShareIcon />
          </span>
        </div>
        <div className="leaderboard__header-image">
          <img
            alt={selectedLeague.leagueName}
            src={selectedLeague.leagueImageURL}
          />
        </div>
        <div className="leaderboard__header-description">
          {selectedLeague.leagueDescription}
        </div>
      </div>
      <Table
        users={selectedLeague.users}
        selectedUser={user}
        league={selectedLeague}
      />
      <NewResultButton />
      <AddResultModal
        users={sortedUsers}
        defaultPlayerOneId={user.id}
        leagueId={selectedLeague.id}
      />
      <CopyLeagueIdModal leagueId={selectedLeague.id} />
      {user.id === selectedLeague.adminId && <EditLeagueButton />}
      {user.id === selectedLeague.adminId && (
        <EditLeagueModal league={selectedLeague} />
      )}
    </div>
  );
};

export default League;
