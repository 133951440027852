const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SELECT_LEAGUE_SUCCESS":
    case "CREATE_LEAGUE":
    case "JOIN_LEAGUE":
      return action.league;
    case "LOGIN_EMAIL_SUCCESS":
    case "LOGIN_REGISTER_SUCCESS":
    case "LOGIN_GOOGLE_SUCCESS":
    case "LOGIN_FACEBOOK_SUCCESS":
    case "SET_LOGGED_IN_USER_SUCCESS":
      return action.leagues && action.leagues.length > 0
        ? action.leagues[0]
        : state;
    case "UPDATE_LEAGUE":
      console.log(
        "Updating selected league in reducer:",
        action.selectedLeague
      );
      return action.selectedLeague ? action.selectedLeague : state;
    case "UPDATE_PROFILE":
      return action.selectedLeague ? action.selectedLeague : state;
    default:
      return state;
  }
};
