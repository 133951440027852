const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_EMAIL_SUCCESS":
    case "LOGIN_REGISTER_SUCCESS":
    case "REGISTER_EMAIL_SUCCESS":
    case "LOGIN_GOOGLE_SUCCESS":
    case "REGISTER_GOOGLE_SUCCESS":
    case "LOGIN_FACEBOOK_SUCCESS":
    case "REGISTER_FACEBOOK_SUCCESS":
    case "UPDATE_PROFILE":
    case "SET_LOGGED_IN_USER_SUCCESS":
      return action.user ? action.user : state;
    case "LOGOUT":
    case "LOGOUT_ERROR":
      return {};
    default:
      return state;
  }
};
