import React, { useMemo } from "react";
import Card from "@material-ui/core/Card";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { selectLeague } from "../../store/actions/leagueActions";
import { leaguesPropType } from "../../utils/propTypeObjects";

const Cards = React.memo(({ leagues }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const navigateToLeague = (card) => {
    dispatch(selectLeague(card.id));
    history.push(`/league/${card.id}`);
  };

  const renderedCards = useMemo(() => {
    console.log("Rendering cards with leagues:", leagues);
    return leagues.map((card) => (
      <Card key={card.id} className="card__component">
        <div className="card__title">{card.leagueName}</div>
        <div className="card__description">{card.leagueDescription}</div>
        <div className="card__cta-container">
          <button
            type="button"
            className="btn btn-block btn-lg btn-success"
            onClick={() => navigateToLeague(card)}
          >
            View League
          </button>
        </div>
      </Card>
    ));
  }, [leagues, history, dispatch]);

  if (leagues && leagues.length > 0) {
    return <div className="cards-container">{renderedCards}</div>;
  } else {
    return <div>There are no leagues available</div>;
  }
});

Cards.propTypes = {
  leagues: leaguesPropType,
};

Cards.displayName = "Cards";

export default Cards;
