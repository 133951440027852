import React, { Component } from "react";
import "./AddResultModal.css";
import { usersPropType } from "../../utils/propTypeObjects";
import utils from "../../utils/utils";
import { addResult } from "../../store/actions/leagueActions";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";

class AddResultModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playerOneId: this.props.defaultPlayerOneId,
      playerOneScore: "",
      playerTwoId: this.setupPlayerTwoId(),
      playerTwoScore: "",
      result: "PLAYER_ONE_WIN",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  calculateNewElo(playerOneOldElo, playerTwoOldElo) {
    if (this.state.result === "PLAYER_ONE_WIN") {
      return [
        utils.getEloNewRating(playerOneOldElo, playerTwoOldElo, 1),
        utils.getEloNewRating(playerTwoOldElo, playerOneOldElo, 0),
      ];
    }
    if (this.state.result === "PLAYER_TWO_WIN") {
      return [
        utils.getEloNewRating(playerOneOldElo, playerTwoOldElo, 0),
        utils.getEloNewRating(playerTwoOldElo, playerOneOldElo, 1),
      ];
    }
    return [
      utils.getEloNewRating(playerOneOldElo, playerTwoOldElo, 0.5),
      utils.getEloNewRating(playerTwoOldElo, playerOneOldElo, 0.5),
    ];
  }

  setupPlayerTwoId() {
    if (this.props.users.length < 2) return this.props.users[0].id; // Ensure ID is returned
    if (
      this.props.defaultPlayerTwoId &&
      this.props.defaultPlayerTwoId !== this.props.defaultPlayerOneId
    )
      return this.props.defaultPlayerTwoId;
    if (
      this.props.defaultPlayerOneId &&
      this.props.defaultPlayerOneId === this.props.users[0].id
    )
      return this.props.users[1].id;
    if (
      this.props.defaultPlayerOneId &&
      this.props.defaultPlayerOneId !== this.props.users[0].id
    )
      return this.props.users[0].id;
    return this.props.users[1].id;
  }

  renderDropdownOptions(id) {
    let playerNameArray = [];
    const { users } = this.props;
    for (let i = 0; i < users.length; i++) {
      playerNameArray.push(
        <option key={users[i].id} id={users[i].id} value={users[i].id}>
          {users[i].displayName}
        </option>
      );
    }

    return playerNameArray;
  }

  handleChange(e) {
    e.preventDefault();
    console.log("e.target.value: ", e.target.value);
    if (
      e.target.id === "playerOneId" &&
      e.target.value === this.state.playerTwoId
    ) {
      this.setState({
        playerTwoId: this.state.playerOneId,
        playerOneId: e.target.value,
      });
    } else if (
      e.target.id === "playerTwoId" &&
      e.target.value === this.state.playerOneId
    ) {
      this.setState({
        playerOneId: this.state.playerTwoId,
        playerTwoId: e.target.value,
      });
    } else {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    document.getElementById("addResultClose").click();
    console.log("this.state: ", this.state);
    this.props.addResult({
      userOneId: this.state.playerOneId,
      userOneScore: this.state.playerOneScore,
      userTwoId: this.state.playerTwoId,
      userTwoScore: this.state.playerTwoScore,
      matchResult: this.state.result,
      leagueId: this.props.leagueId,
    });
  }

  render() {
    return (
      <div className="container create-league-modal">
        <div
          className="modal fade add-league-modal__container"
          id="addResultModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Result</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="container create-league-modal__form">
                <form>
                  <div className="form-group">
                    <label htmlFor="leagueName">Player One Name</label>
                    <select
                      className="form-control"
                      id="playerOneId"
                      aria-describedby="leagueName"
                      placeholder="Player One Name"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.playerOneId}
                    >
                      {this.renderDropdownOptions(this.state.playerOneId)}
                    </select>
                    <label htmlFor="leagueName">Player One Score</label>
                    <input
                      className="form-control"
                      id="playerOneScore"
                      aria-describedby="leagueName"
                      placeholder="Player One Score"
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="leagueDescription">Player Two Name</label>
                    <select
                      className="form-control"
                      id="playerTwoId"
                      placeholder="Player Two Name"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.playerTwoId}
                    >
                      {this.renderDropdownOptions(this.state.playerTwoId)}
                    </select>
                    <label htmlFor="leagueDescription">Player Two Score</label>
                    <input
                      className="form-control"
                      id="playerTwoScore"
                      placeholder="Player Two Score"
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="leagueDescription">Result</label>
                    <select
                      className="form-control"
                      id="result"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.result}
                    >
                      <option value="PLAYER_ONE_WIN">Player One Win</option>
                      <option value="DRAW">Draw</option>
                      <option value="PLAYER_TWO_WIN">Player Two Win</option>
                    </select>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  id="addResultClose"
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddResultModal.propTypes = {
  users: usersPropType,
  defaultPlayerOneId: PropTypes.string,
  defaultPlayerTwoId: PropTypes.string,
  leagueId: PropTypes.string.isRequired,
};

AddResultModal.defaultProps = {
  users: [],
  defaultPlayerOneId: "",
  defaultPlayerTwoId: "",
};

const mapDispatchToProps = (dispatch) => {
  return {
    addResult: (resultObject) => dispatch(addResult(resultObject)),
  };
};

export default connect(null, mapDispatchToProps)(AddResultModal);
