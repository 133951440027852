import React, { useState } from "react";
import "./CreateNewLeagueModal.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createLeague } from "../../store/actions/leagueActions";
import { userPropType } from "../../utils/propTypeObjects";
import { useHistory } from "react-router-dom";

const CreateNewLeagueModal = ({ user, createLeague }) => {
  const [leagueName, setLeagueName] = useState("");
  const [leagueDescription, setLeagueDescription] = useState("");
  const [leagueImageURL, setLeagueImageURL] = useState("");
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.getElementById("newLeagueClose").click();
    try {
      const newLeague = await createLeague(
        leagueName,
        leagueDescription,
        leagueImageURL ||
          "https://static.thenounproject.com/png/901822-200.png",
        user
      );
      history.push(`/league/${newLeague.id}`);
    } catch (error) {
      console.error("Error creating league:", error);
    }
  };

  return (
    <div className="container create-league-modal">
      <div
        className="modal fade add-league-modal__container"
        id="createNewLeagueModal"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Create New League</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="container create-league-modal__form">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="leagueName">League Name</label>
                  <input
                    className="form-control"
                    id="leagueName"
                    aria-describedby="leagueName"
                    placeholder="League Name"
                    value={leagueName}
                    onChange={(e) => setLeagueName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="leagueDescription">League Description</label>
                  <input
                    className="form-control"
                    id="leagueDescription"
                    placeholder="League Description"
                    value={leagueDescription}
                    onChange={(e) => setLeagueDescription(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="leagueImageURL">League Image URL</label>
                  <input
                    className="form-control"
                    id="leagueImageURL"
                    placeholder="League Image URL"
                    value={leagueImageURL}
                    onChange={(e) => setLeagueImageURL(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <button
                id="newLeagueClose"
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateNewLeagueModal.propTypes = {
  user: userPropType,
  createLeague: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  createLeague,
};

export default connect(null, mapDispatchToProps)(CreateNewLeagueModal);
