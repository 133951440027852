import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "./config/firebaseConfig";
import {
  startInitialLoad,
  startLoggedIn,
  startLoggedOut,
} from "./store/actions/authActions";
import Home from "./components/Home/Home";
import League from "./components/League/League";
import Profile from "./components/Profile/Profile";
import Settings from "./components/Settings/Settings";
import Login from "./components/Login/Login";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Loading from "./components/Loading/Loading";
import { getTitleFromRoute } from "./utils/utils";

const App = () => {
  const dispatch = useDispatch();
  const {
    loggedIn,
    authErrorMessage,
    isLoading,
    isInitialLoading,
    route,
    selectedIndex,
  } = useSelector((state) => ({
    loggedIn: state.auth.loggedIn,
    authErrorMessage: state.auth.authErrorMessage,
    isLoading: state.auth.isLoading,
    isInitialLoading: state.loading && state.loading.isInitialLoading,
    route: state.routes.route || "Home",
    selectedIndex: state.routes.selectedIndex || 0,
  }));

  useEffect(() => {
    dispatch(startInitialLoad());
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          await dispatch(startLoggedIn(user));
        } catch (error) {
          console.error("Error fetching user data:", error);
          dispatch(startLoggedOut());
        }
      } else {
        dispatch(startLoggedOut());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  if (isInitialLoading || isLoading) {
    return (
      <Router>
        <Loading />
      </Router>
    );
  }

  const pageTitle = getTitleFromRoute(route);

  if (loggedIn) {
    return (
      <Router>
        <div>
          <div className="app-body">
            <Header title={pageTitle} />
            <div className="main-body">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/league/:leagueId" component={League} />
                <Route exact path="/league" component={League} />
                <Route path="/profile" component={Profile} />
                <Route path="/settings" component={Settings} />
                <Redirect to="/" />
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
      </Router>
    );
  } else {
    return (
      <Router>
        <div>
          <div className="app-body--login">
            <Route
              path="/"
              render={() => <Login authErrorMessage={authErrorMessage} />}
            />
          </div>
        </div>
      </Router>
    );
  }
};

export default App;
