import React, { Component } from "react";
import "./EditLeagueModal.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateProfile } from "../../store/actions/userActions";
import { leaguePropType, userPropType } from "../../utils/propTypeObjects";
import { withRouter } from "react-router-dom";

class EditLeagueModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: this.props.user.displayName || "",
      displayPictureURL: this.props.user.displayPictureURL || "",
      errors: {
        displayName: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value,
      errors: {
        ...this.state.errors,
        displayName:
          e.target.id === "displayName" && e.target.value.trim() === ""
            ? "Display Name is required"
            : "",
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    // Validate if displayName is provided
    if (!this.state.displayName.trim()) {
      this.setState({
        errors: { displayName: "Display Name is required" },
      });
      return; // Stop submission if displayName is empty
    }

    document.getElementById("updateProfileClose").click();
    this.props.updateProfile(
      this.state.displayName,
      this.state.displayPictureURL ||
        "https://previews.123rf.com/images/kritchanut/kritchanut1406/kritchanut140600093/29213195-male-silhouette-avatar-profile-picture.jpg"
    );
    // this.props.history.push('/league');
  }

  render() {
    return (
      <div className="container edit-league-modal">
        <div
          className="modal fade add-league-modal__container"
          id="updateProfileModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Profile</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="container edit-league-modal__form">
                <form>
                  <div className="form-group">
                    <label htmlFor="displayName">Display Name*</label>
                    <input
                      className={`form-control ${
                        this.state.errors.displayName ? "is-invalid" : ""
                      }`}
                      id="displayName"
                      placeholder="Display Name"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.displayName}
                    />
                    {this.state.errors.displayName && (
                      <div className="invalid-feedback">
                        {this.state.errors.displayName}
                      </div>
                    )}
                    <small className="form-text text-muted">
                      Enter Display name
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="displayPictureURL">Profile Pic URL</label>
                    <input
                      className="form-control"
                      id="displayPictureURL"
                      placeholder="Profile pic url"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.displayPictureURL}
                    />
                    <small className="form-text text-muted">
                      Enter the url of your profile pic
                    </small>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  id="updateProfileClose"
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditLeagueModal.propTypes = {
  user: userPropType,
};

const mapStateToProps = (state) => {
  return {
    user: state.user || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (displayName, displayPictureURL) =>
      dispatch(updateProfile(displayName, displayPictureURL)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditLeagueModal));
