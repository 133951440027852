import React, { Component } from "react";
import "./Terms.css";

class TermsAndConditionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = { leagueId: "" };
    this.handleSubmit.bind(this);
    this.handleChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    document.getElementById("tAndCModal").click();
    this.props.joinLeague(this.state.leagueId, this.props.user);
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value,
    });
  }
  render() {
    return (
      <div className="container terms-modal">
        <div
          className="modal fade add-league-modal__container"
          id="TermsAndConditionsModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Terms and Conditions</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="container terms-modal__form">
                <div id="md_en" data-target="out_en" className="agreement_md">
                  <p>Last updated: June 27, 2020</p>
                  <p>
                    Please read these terms and conditions carefully before
                    using Our Service.
                  </p>
                  <h3>Interpretation and Definitions</h3>
                  <h4>Interpretation</h4>
                  <p>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions.
                  </p>
                  <p>
                    The following definitions shall have the same meaning
                    regardless of whether they appear in singular or in plural.
                  </p>
                  <h4>Definitions</h4>
                  <p>For the purposes of these Terms and Conditions:</p>
                  <ul>
                    <li>
                      <p>
                        <strong>Affiliate</strong> means an entity that
                        controls, is controlled by or is under common control
                        with a party, where "control" means ownership of 50% or
                        more of the shares, equity interest or other securities
                        entitled to vote for election of directors or other
                        managing authority.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Company</strong> (referred to as either "the
                        Company", "We", "Us" or "Our" in this Agreement) refers
                        to Friends League
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Country</strong> refers to: United Kingdom
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Device</strong> means any device that can access
                        the Service such as a computer, a cellphone or a digital
                        tablet.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Service</strong> refers to the Website and
                        associated App
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Terms and Conditions</strong> (also referred as
                        "Terms") mean these Terms and Conditions that form the
                        entire agreement between You and the Company regarding
                        the use of the Service.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Third-party Social Media Service</strong> means
                        any services or content (including data, information,
                        products or services) provided by a third-party that may
                        be displayed, included or made available by the Service.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Website</strong> refers to Friends League,
                        accessible from&nbsp;
                        <a
                          href="https://friends-league.firebaseapp.com/"
                          rel="external nofollow noopener"
                          target="_blank"
                        >
                          https://friends-league.firebaseapp.com/
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>You</strong> means the individual accessing or
                        using the Service, or the company, or other legal entity
                        on behalf of which such individual is accessing or using
                        the Service, as applicable.
                      </p>
                    </li>
                  </ul>
                  <h3>Acknowledgment</h3>
                  <p>
                    These are the Terms and Conditions governing the use of this
                    Service and the agreement that operates between You and the
                    Company. These Terms and Conditions set out the rights and
                    obligations of all users regarding the use of the Service.
                  </p>
                  <p>
                    Your access to and use of the Service is conditioned on Your
                    acceptance of and compliance with these Terms and
                    Conditions. These Terms and Conditions apply to all
                    visitors, users and others who access or use the Service.
                  </p>
                  <p>
                    By accessing or using the Service You agree to be bound by
                    these Terms and Conditions. If You disagree with any part of
                    these Terms and Conditions then You may not access the
                    Service.
                  </p>
                  <p>
                    You represent that you are over the age of 18. The Company
                    does not permit those under 18 to use the Service.
                  </p>
                  <p>
                    Your access to and use of the Service is also conditioned on
                    Your acceptance of and compliance with the Privacy Policy of
                    the Company. Our Privacy Policy describes Our policies and
                    procedures on the collection, use and disclosure of Your
                    personal information when You use the Application or the
                    Website and tells You about Your privacy rights and how the
                    law protects You. Please read Our Privacy Policy carefully
                    before using Our Service.
                  </p>
                  <h3>Intellectual Property</h3>
                  <p>
                    The Service and its original content, features and
                    functionality are and will remain the exclusive property of
                    Friends League and its licensors.
                  </p>
                  <p>
                    When you upload content, you give to Friends League a
                    worldwide, non-exclusive, royalty-free, transferable licence
                    (with right to sub-licence) to use, reproduce, distribute,
                    prepare derivative works of, display, and perform that
                    Content in connection with the provision of the Service and
                    otherwise in connection with the provision of the Service
                    and Friends League business.
                  </p>
                  <h3>Links to Other Websites</h3>
                  <p>
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by the Company.
                  </p>
                  <p>
                    The Company has no control over, and assumes no
                    responsibility for, the content, privacy policies, or
                    practices of any third party web sites or services. You
                    further acknowledge and agree that the Company shall not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in
                    connection with the use of or reliance on any such content,
                    goods or services available on or through any such web sites
                    or services.
                  </p>
                  <p>
                    We strongly advise You to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that You visit.
                  </p>
                  <h3>Accounts</h3>
                  <p>
                    When you create an account with us, you must provide us
                    information that is accurate, complete, and current at all
                    times. Failure to do so constitutes a breach of the Terms,
                    which may result in immediate termination of your account on
                    our Service.
                  </p>
                  <p>
                    You are responsible for safeguarding the password that you
                    use to access the Service and for any activities or actions
                    under your password, whether your password is with our
                    Service or a third-party service.
                  </p>
                  <p>
                    You agree not to disclose your password to any third party.
                    You must notify us immediately upon becoming aware of any
                    breach of security or unauthorized use of your account.
                  </p>
                  <h3>Content</h3>
                  <p>
                    Our Service allows you to post, link, store, share and
                    otherwise make available certain information, text,
                    graphics, videos, or other material ("Content"). You are
                    responsible for the Content that you post to the Service,
                    including its legality, reliability, and appropriateness.
                  </p>
                  <p>
                    By posting Content to the Service, you grant us the right
                    and license to use, modify, publicly perform, publicly
                    display, reproduce, and distribute such Content on and
                    through the Service. You retain any and all of your rights
                    to any Content you submit, post or display on or through the
                    Service and you are responsible for protecting those rights.
                    You agree that this license includes the right for us to
                    make your Content available to other users of the Service,
                    who may also use your Content subject to these Terms.
                  </p>
                  <p>
                    You represent and warrant that: (i) the Content is yours
                    (you own it) or you have the right to use it and grant us
                    the rights and license as provided in these Terms, and (ii)
                    the posting of your Content on or through the Service does
                    not violate the privacy rights, publicity rights,
                    copyrights, contract rights or any other rights of any
                    person.
                  </p>
                  <h3>Termination</h3>
                  <p>
                    We may terminate or suspend Your access immediately, without
                    prior notice or liability, for any reason whatsoever,
                    including without limitation if You breach these Terms and
                    Conditions.
                  </p>
                  <p>
                    Upon termination, Your right to use the Service will cease
                    immediately.
                  </p>
                  <h3>Indemnification</h3>
                  <p>
                    You agree to defend, indemnify and hold harmless Friends
                    League and its licensee and licensors, and their employees,
                    contractors, agents, officers and directors, from and
                    against any and all claims, damages, obligations, losses,
                    liabilities, costs or debt, and expenses (including but not
                    limited to attorney's fees), resulting from or arising out
                    of a) your use and access of the Service, by you or any
                    person using your account and password, or b) a breach of
                    these Terms.
                  </p>
                  <h3>Limitation of Liability</h3>
                  <p>
                    In no event shall Friends League, nor its directors,
                    employees, partners, agents, suppliers, or affiliates, be
                    liable for any indirect, incidental, special, consequential
                    or punitive damages, including without limitation, loss of
                    profits, data, use, goodwill, or other intangible losses,
                    resulting from (i) your access to or use of or inability to
                    access or use the Service; (ii) any conduct or content of
                    any third party on the Service; (iii) any content obtained
                    from the Service; and (iv) unauthorized access, use or
                    alteration of your transmissions or content, whether based
                    on warranty, contract, tort (including negligence) or any
                    other legal theory, whether or not we have been informed of
                    the possibility of such damage, and even if a remedy set
                    forth herein is found to have failed of its essential
                    purpose.
                  </p>
                  <h3>"AS IS" and "AS AVAILABLE" Disclaimer</h3>
                  <p>
                    The Service is provided to You "AS IS" and "AS AVAILABLE"
                    and with all faults and defects without warranty of any
                    kind. To the maximum extent permitted under applicable law,
                    the Company, on its own behalf and on behalf of its
                    Affiliates and its and their respective licensors and
                    service providers, expressly disclaims all warranties,
                    whether express, implied, statutory or otherwise, with
                    respect to the Service, including all implied warranties of
                    merchantability, fitness for a particular purpose, title and
                    non-infringement, and warranties that may arise out of
                    course of dealing, course of performance, usage or trade
                    practice. Without limitation to the foregoing, the Company
                    provides no warranty or undertaking, and makes no
                    representation of any kind that the Service will meet Your
                    requirements, achieve any intended results, be compatible or
                    work with any other software, applications, systems or
                    services, operate without interruption, meet any performance
                    or reliability standards or be error free or that any errors
                    or defects can or will be corrected.
                  </p>
                  <p>
                    Without limiting the foregoing, neither the Company nor any
                    of the company's provider makes any representation or
                    warranty of any kind, express or implied: (i) as to the
                    operation or availability of the Service, or the
                    information, content, and materials or products included
                    thereon; (ii) that the Service will be uninterrupted or
                    error-free; (iii) as to the accuracy, reliability, or
                    currency of any information or content provided through the
                    Service; or (iv) that the Service, its servers, the content,
                    or e-mails sent from or on behalf of the Company are free of
                    viruses, scripts, trojan horses, worms, malware, timebombs
                    or other harmful components.
                  </p>
                  <p>
                    Some jurisdictions do not allow the exclusion of certain
                    types of warranties or limitations on applicable statutory
                    rights of a consumer, so some or all of the above exclusions
                    and limitations may not apply to You. But in such a case the
                    exclusions and limitations set forth in this section shall
                    be applied to the greatest extent enforceable under
                    applicable law.
                  </p>
                  <h3>Governing Law</h3>
                  <p>
                    These Terms shall be governed and construed in accordance
                    with the laws of United Kingdom without regard to its
                    conflict of law provisions.
                  </p>
                  <p>
                    Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. If any
                    provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these
                    Terms will remain in effect. These Terms constitute the
                    entire agreement between us regarding our Service, supersede
                    and replace any prior agreements we might have between us
                    regarding the Service.
                  </p>
                  <h3>Disputes Resolution</h3>
                  <p>
                    If You have any concern or dispute about the Service, You
                    agree to first try to resolve the dispute informally by
                    contacting the Company.
                  </p>
                  <h3>For European Union (EU) Users</h3>
                  <p>
                    If You are a European Union consumer, you will benefit from
                    any mandatory provisions of the law of the country in which
                    you are resident in.
                  </p>
                  <h3>United States Legal Compliance</h3>
                  <p>
                    You represent and warrant that (i) You are not located in a
                    country that is subject to the United States government
                    embargo, or that has been designated by the United States
                    government as a "terrorist supporting" country, and (ii) You
                    are not listed on any United States government list of
                    prohibited or restricted parties.
                  </p>
                  <h3>Severability and Waiver</h3>
                  <h4>Severability</h4>
                  <p>
                    If any provision of these Terms is held to be unenforceable
                    or invalid, such provision will be changed and interpreted
                    to accomplish the objectives of such provision to the
                    greatest extent possible under applicable law and the
                    remaining provisions will continue in full force and effect.
                  </p>
                  <h4>Waiver</h4>
                  <p>
                    Except as provided herein, the failure to exercise a right
                    or to require performance of an obligation under this Terms
                    shall not effect a party's ability to exercise such right or
                    require such performance at any time thereafter nor shall be
                    the waiver of a breach constitute a waiver of any subsequent
                    breach.
                  </p>
                  <h3>Translation Interpretation</h3>
                  <p>
                    These Terms and Conditions may have been translated if We
                    have made them available to You on our Service. You agree
                    that the original English text shall prevail in the case of
                    a dispute.
                  </p>
                  <h3>Changes to These Terms and Conditions</h3>
                  <p>
                    We reserve the right, at Our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    We will make reasonable efforts to provide at least 30 days'
                    notice prior to any new terms taking effect. What
                    constitutes a material change will be determined at Our sole
                    discretion.
                  </p>
                  <p>
                    By continuing to access or use Our Service after those
                    revisions become effective, You agree to be bound by the
                    revised terms. If You do not agree to the new terms, in
                    whole or in part, please stop using the website and the
                    Service.
                  </p>
                  <h3>Contact Us</h3>
                  <p>
                    If you have any questions about these Terms and Conditions,
                    You can contact us:
                  </p>
                  <ul>
                    <li>By email: contact-us@friendsleague.com</li>
                  </ul>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  id="tAndCModal"
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsAndConditionsModal;
