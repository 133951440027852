import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { getUserData, mapRatings, graphqlRequest } from "./ActionUtils";

const graphqlURL =
  process.env.NODE_ENV === "production"
    ? "https://friends-league-server-323b33c9eb8e.herokuapp.com/graphql"
    : "http://localhost:4000/graphql";

export const updateProfile = (displayName, displayPictureURL) => {
  return async (dispatch, getState) => {
    dispatch({ type: "LOADING_START" });

    const { auth } = getState();
    const userId = auth.user.id;

    const updateUserMutation = {
      mutation: {
        updateUser: {
          __args: {
            id: userId,
            displayName,
            displayPictureURL,
          },
          id: true,
          displayName: true,
          displayPictureURL: true,
        },
      },
    };

    try {
      await graphqlRequest(updateUserMutation, "mutation");

      const updatedUser = { ...auth.user, displayName, displayPictureURL };
      dispatch({
        type: "UPDATE_PROFILE",
        user: updatedUser,
      });

      dispatch({ type: "LOADING_COMPLETE" });
    } catch (err) {
      console.log(err);
      dispatch({ type: "UPDATE_PROFILE_ERROR", error: err });
    }
  };
};
