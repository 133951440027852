// src/store/reducers/routesReducer.js

const initialState = {
  route: "Home",
  selectedIndex: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_EMAIL_SUCCESS":
    case "LOGIN_REGISTER_SUCCESS":
    case "LOGIN_GOOGLE_SUCCESS":
    case "LOGIN_FACEBOOK_SUCCESS":
    case "SET_LOGGED_IN_USER_SUCCESS":
      return {
        ...state,
        route: "Home",
        selectedIndex: 0,
      };
    case "UPDATE_ROUTES":
      return {
        ...state,
        route: action.payload.route,
        selectedIndex: action.payload.selectedIndex,
      };
    case "SELECT_LEAGUE_SUCCESS":
    case "CREATE_LEAGUE":
    case "JOIN_LEAGUE":
      return {
        ...state,
        route: "League",
        selectedIndex: 1,
      };
    default:
      return state;
  }
};
