const initialState = {
  leagues: [],
  newLeagueCreated: false,
  newLeagueJoined: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_LEAGUE":
      return {
        ...state,
        leagues: [...state.leagues, action.league],
        newLeagueCreated: true,
      };
    case "JOIN_LEAGUE":
      return {
        ...state,
        leagues: [...state.leagues, action.league],
        newLeagueJoined: true,
      };
    case "UPDATE_LEAGUE":
      console.log("Updating leagues in reducer:", action.leagues);
      return {
        ...state,
        leagues: action.leagues,
      };
    case "UPDATE_LEAGUES":
      return {
        ...state,
        leagues: action.leagues,
      };
    case "RESET_NEW_LEAGUE_CREATED":
      return {
        ...state,
        newLeagueCreated: false,
      };
    case "RESET_NEW_LEAGUE_JOINED":
      return {
        ...state,
        newLeagueJoined: false,
      };
    case "LOGIN_EMAIL_SUCCESS":
    case "LOGIN_GOOGLE_SUCCESS":
    case "LOGIN_FACEBOOK_SUCCESS":
    case "SET_LOGGED_IN_USER_SUCCESS":
      return {
        ...state,
        leagues: action.leagues || state.leagues,
      };
    default:
      return state;
  }
};
