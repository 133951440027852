// src/store/reducers/authReducer.js

const initialState = {
  loggedIn: false,
  authErrorMessage: "",
  isLoading: false,
  user: null,
  leagues: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "START_INITIAL_LOAD":
      return {
        ...state,
        isLoading: true,
      };
    case "LOGIN_EMAIL_SUCCESS":
    case "LOGIN_GOOGLE_SUCCESS":
    case "LOGIN_FACEBOOK_SUCCESS":
      return {
        ...state,
        isLoading: false,
        authErrorMessage: "",
        // Do not set 'loggedIn' here; wait for 'SET_LOGGED_IN_USER_SUCCESS'
      };
    case "REGISTER_EMAIL_SUCCESS":
      return {
        ...state,
        isLoading: false,
        authErrorMessage: "",
        // Do not set 'loggedIn' here; wait for 'SET_LOGGED_IN_USER_SUCCESS'
      };
    case "SET_LOGGED_IN_USER_PENDING":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_LOGGED_IN_USER_SUCCESS":
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        leagues: action.leagues || [],
        isLoading: false,
        authErrorMessage: "",
      };
    case "SET_LOGGED_IN_USER_ERROR":
      return {
        ...state,
        loggedIn: false,
        authErrorMessage: action.error.message,
        isLoading: false,
      };
    case "LOGIN_ERROR":
    case "REGISTER_ERROR":
      return {
        ...state,
        loggedIn: false,
        authErrorMessage: action.error.message,
        isLoading: false,
      };
    case "LOGOUT":
    case "START_LOGGED_OUT":
      return {
        ...state,
        loggedIn: false,
        user: null,
        leagues: [],
        isLoading: false,
        authErrorMessage: "",
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        user: action.user, // Update user information
      };
    case "UPDATE_PROFILE_ERROR":
      return {
        ...state,
        authErrorMessage: action.error.message,
      };
    default:
      return state;
  }
};
