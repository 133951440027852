import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyBwM1PVCdVCvxNgWG_7XD6CwTIaqnR8sp0",
  authDomain: "friends-league.firebaseapp.com",
  databaseURL: "https://friends-league.firebaseio.com",
  projectId: "friends-league",
  storageBucket: "friends-league.appspot.com",
  messagingSenderId: "439003456960",
  appId: "1:439003456960:web:3afad2bffa8e5cb22e9425",
};

// Initialize Firebase if it hasn't been initialized already
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);

// Export the app if you need it elsewhere
export default app;
