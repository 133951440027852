import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RemoveIcon from '@material-ui/icons/Remove';
import { usersPropType, userPropType, leaguePropType } from '../../utils/propTypeObjects';

const ArrowIcon = ({arrowType}) => {
    if (arrowType === 'UP') return <ArrowDropUpIcon style={{ color: '#4caf50', fontSize: 40, marginBottom: '1px' }}/>
    if (arrowType === 'DOWN') return <ArrowDropDownIcon style={{ color: '#dc004e', fontSize: 40, marginBottom: '4px' }}/>
    return <RemoveIcon style={{ color: '#00000042', fontSize: 40, marginBottom: '4px' }}/>
}

const addDefaultSrc = (e) => {
    e.target.src = '../../../silhouette-avatar-profile-picture.jpg'
}

const Table = (props) => {
    const { users, selectedUser, league } = props;
    if (users) {
        let sortedusers = users.sort((a, b) => b.elo - a.elo);
        return (
            <table className="leaderboard__table">
                <tbody>
                    {sortedusers.map((user, index) => {
                        let comparisonElo = 0;
                        let arrowType = '';
                        if (league.recentResult && user.id === league.recentResult.playerOneId) comparisonElo = league.recentResult.playerOneElo
                        else if (league.recentResult && user.id === league.recentResult.playerTwoId) comparisonElo = league.recentResult.playerTwoElo

                        if (comparisonElo && comparisonElo < user.elo) arrowType = 'UP'
                        else if (comparisonElo && comparisonElo > user.elo) arrowType = 'DOWN'
                        else if (comparisonElo && comparisonElo === user.elo) arrowType = 'SAME'

                        return(
                            <tr className={`leaderboard__table-row ${(user.id === selectedUser.id) ? 'selected-user' : '' }`} key={user.id}>
                                <td className="leaderboard__table-row--number">{index + 1}</td>
                                <td className="leaderboard__table-row--image"><img onError={addDefaultSrc} alt={user.displayName} src={user.displayPictureURL}/></td>
                                <td className="leaderboard__table-row--name">{user.displayName}</td>
                                <td className="leaderboard__table-row--elo">{comparisonElo ? <ArrowIcon arrowType={arrowType}/> : null}{user.elo}</td>
                                <td className="leaderboard__table-row--icon"><InfoIcon/></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    } else {
        return <div>There are no users in this league</div>;
    }
};

Table.propTypes = {
    users: usersPropType,
    selectedUser: userPropType,
    league: leaguePropType,
};

export default Table;