const initialState = {
  isLoading: false,
  isInitialLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_EMAIL_SUCCESS":
    case "LOGIN_REGISTER_SUCCESS":
    case "REGISTER_EMAIL_SUCCESS":
    case "LOGIN_GOOGLE_SUCCESS":
    case "REGISTER_GOOGLE_SUCCESS":
    case "LOGIN_FACEBOOK_SUCCESS":
    case "REGISTER_FACEBOOK_SUCCESS":
    case "LOGIN_ERROR":
    case "CREATE_LEAGUE":
    case "JOIN_LEAGUE":
    case "UPDATE_LEAGUE":
    case "UPDATE_PROFILE":
    case "GET_LEAGUE_SUCCESS":
    case "SET_LOGGED_IN_USER_SUCCESS":
      return {
        isLoading: false,
        isInitialLoading: false,
      };
    case "LOADING_START":
      return {
        isLoading: true,
        isInitialLoading: false,
      };
    case "START_LOGGED_IN":
    case "START_LOGGED_OUT":
      return {
        isLoading: false,
        isInitialLoading: false,
      };
    case "START_INITIAL_LOAD":
      return {
        isLoading: false,
        isInitialLoading: true,
      };
    default:
      return state;
  }
};
