import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import LeagueIcon from "@material-ui/icons/Gamepad";
import ProfileIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateRoutes } from "../../store/actions/routesActions";
import "./Footer.css";

const styles = {
  root: {
    width: 500,
  },
};

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, value) {
    const { updateRoutes, history } = this.props;
    const routeMap = ["/", "/league", "/profile", "/settings"];
    updateRoutes({ selectedIndex: value, route: routeMap[value] });
    history.push(routeMap[value]);
  }

  render() {
    const { selectedIndex } = this.props;

    return (
      <div className="footer">
        <BottomNavigation
          value={selectedIndex}
          onChange={this.handleChange}
          showLabels
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="League" icon={<LeagueIcon />} />
          <BottomNavigationAction label="Profile" icon={<ProfileIcon />} />
          <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
        </BottomNavigation>
      </div>
    );
  }
}

Footer.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  updateRoutes: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired, // Add this line
};

const mapStateToProps = (state) => ({
  selectedIndex: state.routes.selectedIndex,
});

const mapDispatchToProps = {
  updateRoutes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Footer)));
