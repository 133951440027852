import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  register,
  loginWithEmail,
  loginWithGoogle,
  loginWithFacebook,
} from "../../store/actions/authActions";
import TermsAndConditionsModal from "../modals/TermsAndConditionsModal";
import PrivacyNoticeModal from "../modals/PrivacyNoticeModal";
import "./Login.css";

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const authErrorMessage = useSelector((state) => state.auth.authErrorMessage);
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [profilePicUrl, setProfilePicUrl] = useState(
    "https://previews.123rf.com/images/kritchanut/kritchanut1406/kritchanut140600093/29213195-male-silhouette-avatar-profile-picture.jpg"
  );
  const [isRegister, setIsRegister] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (authErrorMessage) {
      setErrorMessage(authErrorMessage);
    }
  }, [authErrorMessage]);

  useEffect(() => {
    if (loggedIn) {
      history.push("/");
    }
  }, [loggedIn, history]);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setErrorMessage("Please fill out all the fields");
    } else {
      dispatch(loginWithEmail(email, password));
    }
  };

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    if (
      email === "" ||
      password === "" ||
      displayName === "" ||
      profilePicUrl === ""
    ) {
      setErrorMessage("Please fill out all the fields");
    } else {
      dispatch(register(email, password, displayName, profilePicUrl));
    }
  };

  const handleGoogleLogin = (e) => {
    e.preventDefault();
    dispatch(loginWithGoogle());
  };

  const handleFacebookLogin = (e) => {
    e.preventDefault();
    dispatch(loginWithFacebook());
  };

  return (
    <div className="login">
      <img
        alt="Friends League logo"
        className="login__image"
        src="../../../friends-league-logo-1.webp"
      />
      <form>
        {!isRegister ? (
          <div>
            <div className="text-center social-btn">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={handleFacebookLogin}
              >
                <i className="fa fa-facebook" /> Login with <b>Facebook</b>
              </button>
              <button
                type="button"
                className="btn btn-danger btn-block"
                onClick={handleGoogleLogin}
              >
                <i className="fa fa-google" /> Login with <b>Google</b>
              </button>
            </div>
            <div className="or-seperator">
              <i>or login with email</i>
            </div>
          </div>
        ) : (
          <h2>Register</h2>
        )}
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-addon">
              <i className="fa fa-envelope" />
            </span>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Email"
              id="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-addon">
              <i className="fa fa-lock" />
            </span>
            <input
              type="password"
              className="form-control"
              name="password"
              placeholder="Password"
              id="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        {isRegister && (
          <div>
            <div className="form-group">
              <div className="input-group">
                <span className="input-group-addon">
                  <i className="fa fa-user" />
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="displayName"
                  placeholder="Display name"
                  id="displayName"
                  required
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-group">
                <span className="input-group-addon">
                  <i className="fa fa-image" />
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="profilePicUrl"
                  placeholder="Profile pic URL"
                  id="profilePicUrl"
                  required
                  value={profilePicUrl}
                  onChange={(e) => setProfilePicUrl(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
        {errorMessage && (
          <div className="login__error-message">{errorMessage}</div>
        )}
        <div className="form-group">
          {!isRegister ? (
            <button
              type="button"
              className="btn btn-success btn-block login-btn"
              onClick={handleLoginSubmit}
            >
              Login
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success btn-block login-btn"
              onClick={handleRegisterSubmit}
            >
              Register
            </button>
          )}
        </div>
        {isRegister && (
          <div>
            By signing up you agree with our{" "}
            <span
              className="terms-and-conditions"
              data-toggle="modal"
              data-target="#TermsAndConditionsModal"
            >
              Terms and Conditions
            </span>{" "}
            and our{" "}
            <span
              className="terms-and-conditions"
              data-toggle="modal"
              data-target="#PrivacyNoticeModal"
            >
              Privacy Notice
            </span>
            .
          </div>
        )}
        {isRegister && <TermsAndConditionsModal />}
        {isRegister && <PrivacyNoticeModal />}
        <div className="clearfix">
          {!isRegister ? (
            <button
              type="button"
              className="pull-right text-success text-btn"
              onClick={() => setIsRegister(true)}
            >
              Register?
            </button>
          ) : (
            <button
              type="button"
              className="pull-right text-success text-btn"
              onClick={() => setIsRegister(false)}
            >
              Login?
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Login);
