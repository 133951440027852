import React, {Component} from 'react';
import './CopyLeagueIdModal.css';

class CopyLeagueIdModal extends Component {
    constructor(props) {
        super(props);
        this.copyStringToClipboard.bind(this);
    }

    copyStringToClipboard (str) {
        // Create new element
        let el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
        // TODO swap out with a toast message
        alert('Copied!')
    }
    render() {
        return (
            <div className="container copy-league-id-modal">
                <div className="modal fade add-league-modal__container" id="copyLeagueIdModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Copy League ID</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="container copy-league-id-modal__form">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="leagueId">League ID: {this.props.leagueId}</label>
                                    </div>
                                    <button type="button" className="btn btn-primary" onClick={(e) => this.copyStringToClipboard(this.props.leagueId)}>Copy to clip board</button>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button id="copyLeagueIdClose" type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CopyLeagueIdModal;