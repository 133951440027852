const getEloRatingDelta = (myRating, opponentRating, myGameResult) => {
  if ([0, 0.5, 1].indexOf(myGameResult) === -1) {
    return null;
  }
  const myChanceToWin =
    1 / (1 + Math.pow(10, (opponentRating - myRating) / 400));
  return Math.round(32 * (myGameResult - myChanceToWin));
};

const utils = {
  getEloNewRating: (myRating, opponentRating, myGameResult) => {
    return myRating + getEloRatingDelta(myRating, opponentRating, myGameResult);
  },
};

export const getTitleFromRoute = (route) => {
  const routeTitles = {
    "/": "Home",
    Home: "Home",
    "/league": "League",
    League: "League",
    "/profile": "Profile",
    Profile: "Profile",
    "/settings": "Settings",
    Settings: "Settings",
  };

  return routeTitles[route] || "Friends League";
};

export default utils;
