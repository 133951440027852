import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { updateLeague } from "../../store/actions/leagueActions";
import "./EditLeagueModal.css";

const EditLeagueModal = ({ league }) => {
  const [leagueName, setLeagueName] = useState(league.leagueName);
  const [leagueDescription, setLeagueDescription] = useState(
    league.leagueDescription
  );
  const [leagueImageURL, setLeagueImageURL] = useState(league.leagueImageURL);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setLeagueName(league.leagueName);
    setLeagueDescription(league.leagueDescription);
    setLeagueImageURL(league.leagueImageURL);
  }, [league]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedLeague = await dispatch(
        updateLeague(
          leagueName,
          leagueDescription,
          leagueImageURL,
          league.id,
          user.id
        )
      );
      console.log("League updated successfully:", updatedLeague);
      document.getElementById("editLeagueClose").click();
    } catch (error) {
      console.error("Error updating league:", error);
    }
  };

  return (
    <div className="container edit-league-modal">
      <div className="modal fade" id="editLeagueModal" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit League</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="container edit-league-modal__form">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="leagueName">League Name</label>
                  <input
                    className="form-control"
                    id="leagueName"
                    value={leagueName}
                    onChange={(e) => setLeagueName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="leagueDescription">League Description</label>
                  <input
                    className="form-control"
                    id="leagueDescription"
                    value={leagueDescription}
                    onChange={(e) => setLeagueDescription(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="leagueImageURL">League Image URL</label>
                  <input
                    className="form-control"
                    id="leagueImageURL"
                    value={leagueImageURL}
                    onChange={(e) => setLeagueImageURL(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Update League
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <button
                id="editLeagueClose"
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EditLeagueModal.propTypes = {
  league: PropTypes.shape({
    id: PropTypes.string.isRequired,
    leagueName: PropTypes.string.isRequired,
    leagueDescription: PropTypes.string,
    leagueImageURL: PropTypes.string,
  }).isRequired,
};

export default EditLeagueModal;
