import React, { useEffect, useCallback } from "react";
import { useQuery, gql } from "@apollo/client";
import PropTypes from "prop-types";
import NewLeagueButton from "./NewLeagueButton";
import Cards from "./Cards";
import AddLeagueModal from "../modals/AddLeagueModal";
import CreateNewLeagueModal from "../modals/CreateNewLeagueModal";
import JoinExistingLeagueModal from "../modals/JoinExistingLeagueModal";
import { useSelector, useDispatch } from "react-redux";
import { updateLeagues } from "../../store/actions/leagueActions";
import "./Home.css";

const GET_USER_LEAGUES = gql`
  query GetUserLeagues($registrationId: String!) {
    getUserByRegistrationId(registrationId: $registrationId) {
      leagues {
        id
        leagueName
        leagueDescription
        leagueImageURL
        adminId
        users {
          id
          displayName
        }
      }
    }
  }
`;

const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const leagues = useSelector((state) => state.leagues.leagues);
  const newLeagueCreated = useSelector(
    (state) => state.leagues.newLeagueCreated
  );
  const newLeagueJoined = useSelector((state) => state.leagues.newLeagueJoined);

  const { loading, error, data, refetch } = useQuery(GET_USER_LEAGUES, {
    variables: { registrationId: user?.registrationId },
    skip: !user?.registrationId,
  });

  const refreshLeagues = useCallback(() => {
    if (user?.registrationId) {
      refetch();
    }
  }, [user, refetch]);

  useEffect(() => {
    console.log("Leagues changed or other trigger occurred");
    refreshLeagues();
    if (newLeagueCreated) {
      dispatch({ type: "RESET_NEW_LEAGUE_CREATED" });
    }
    if (newLeagueJoined) {
      dispatch({ type: "RESET_NEW_LEAGUE_JOINED" });
    }
  }, [newLeagueCreated, newLeagueJoined, refreshLeagues, dispatch, leagues]);

  useEffect(() => {
    if (data?.getUserByRegistrationId?.leagues) {
      dispatch(updateLeagues(data.getUserByRegistrationId.leagues));
    }
  }, [data, dispatch]);

  // Add this effect to log leagues when they change
  useEffect(() => {
    console.log("Leagues in Home component:", leagues);
  }, [leagues]);

  console.log("Home component rendering");

  if (!user || !user.registrationId) {
    return <div>User not found</div>;
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching leagues: {error.message}</div>;

  return (
    <div className="home">
      {leagues.length > 0 ? (
        <div className="cards-container">
          <Cards leagues={leagues} />
        </div>
      ) : (
        <h2>Please click the '+' button to join a league</h2>
      )}
      <NewLeagueButton />
      <AddLeagueModal />
      <CreateNewLeagueModal user={user} />
      <JoinExistingLeagueModal user={user} />
    </div>
  );
};

Home.propTypes = {
  user: PropTypes.object,
};

export default React.memo(Home);
