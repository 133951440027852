import PropTypes from 'prop-types';

export const userPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayPictureURL: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    elo: PropTypes.number,
}).isRequired;

export const usersPropType = PropTypes.arrayOf(userPropType).isRequired;

export const leaguePropType = PropTypes.shape({
    leagueName: PropTypes.string.isRequired,
    leagueImageURL: PropTypes.string.isRequired,
    leagueDescription: PropTypes.string.isRequired,
    recentResult: PropTypes.shape({
        playerOneId: PropTypes.string,
        playerOneElo: PropTypes.number,
        playerTwoId: PropTypes.string,
        playerTwoElo: PropTypes.number,
    })
}).isRequired;

export const leaguesPropType = PropTypes.arrayOf(leaguePropType).isRequired;

export const historyPropType = PropTypes.shape({
    push: PropTypes.func.isRequired
}).isRequired;
