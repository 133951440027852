// src/store/actions/ActionUtils.js

import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { auth } from "../../config/firebaseConfig";

const graphqlURL =
  process.env.NODE_ENV === "production"
    ? "https://friends-league-server-323b33c9eb8e.herokuapp.com/graphql"
    : "http://localhost:4000/graphql";

export const graphqlRequest = async (operation) => {
  // Get Firebase ID token
  const token = await auth.currentUser.getIdToken();

  const graphqlQuery = jsonToGraphQLQuery(operation, { pretty: true });

  return fetch(graphqlURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ query: graphqlQuery }),
  }).then((response) => response.json());
};

export const userQuery = (id, isRegistration) => {
  const queryName = isRegistration ? "getUserByRegistrationId" : "getUser";
  const idField = isRegistration ? "registrationId" : "id";

  return {
    query: {
      [queryName]: {
        __args: {
          [idField]: id,
        },
        displayName: true,
        displayPictureURL: true,
        email: true,
        id: true,
        registrationId: true,
        leagues: {
          leagueDescription: true,
          id: true,
          leagueImageURL: true,
          leagueName: true,
          adminId: true,
          users: {
            id: true,
            displayName: true,
            displayPictureURL: true,
            email: true,
            ratings: {
              id: true,
              leagueId: true,
              userId: true,
              elo: true,
            },
          },
        },
      },
    },
  };
};

export const getUserData = async (id, isRegistrationId) => {
  const queryName = isRegistrationId ? "getUserByRegistrationId" : "getUser";
  const idField = isRegistrationId ? "registrationId" : "id";

  const queryObject = {
    query: {
      [queryName]: {
        __args: {
          [idField]: id,
        },
        displayName: true,
        displayPictureURL: true,
        email: true,
        id: true,
        registrationId: true,
        leagues: {
          leagueDescription: true,
          id: true,
          leagueImageURL: true,
          leagueName: true,
          adminId: true,
          users: {
            id: true,
            displayName: true,
            displayPictureURL: true,
            email: true,
            ratings: {
              id: true,
              leagueId: true,
              userId: true,
              elo: true,
            },
          },
        },
      },
    },
  };

  const data = await graphqlRequest(queryObject);
  return mapRatings(data, queryName);
};

export const mapRatings = (data, queryName) => {
  const userData = data.data[queryName];

  if (!userData) {
    return null;
  }

  const newLeagues = userData.leagues
    ? userData.leagues.map((league) => {
        league.users = league.users.map((user) => {
          const rating = user.ratings.find(
            (rating) => rating.leagueId === league.id
          );
          user.elo = rating ? rating.elo : 1111;
          return user;
        });
        return league;
      })
    : [];

  return {
    user: {
      displayName: userData.displayName,
      displayPictureURL: userData.displayPictureURL,
      email: userData.email,
      id: userData.id,
      registrationId: userData.registrationId,
    },
    leagues: newLeagues,
  };
};
