import React from 'react';
import PropTypes from 'prop-types';
import './Header.css';

const Header = (props) => <div className='header__title'>{props.title}</div>;

Header.propTypes = {
    title: PropTypes.string.isRequired
};

export default Header;