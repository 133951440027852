import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./Profile.css";
import { logout } from "../../store/actions/authActions";
import UpdateProfileModal from "../modals/UpdateProfileModal";
import Button from "@material-ui/core/Button";

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user || {});

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  return (
    <div className="profile">
      <div className="profile__header">
        <div className="profile__header-title">
          <span>{user.displayName}</span>
        </div>
        <div className="profile__header-image">
          <img alt={user.displayName} src={user.displayPictureURL} />
        </div>
        <div className="profile__header-footer" />
      </div>
      <button
        type="button"
        className="btn btn-primary btn-block logout-btn"
        data-toggle="modal"
        data-target="#updateProfileModal"
      >
        Update Profile
      </button>
      <button
        type="button"
        className="btn btn-dark btn-block logout-btn"
        onClick={handleLogout}
      >
        Logout
      </button>
      <UpdateProfileModal />
    </div>
  );
};

export default Profile;
