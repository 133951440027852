import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./JoinExistingLeagueModal.css";
import { joinLeague } from "../../store/actions/leagueActions";

const JoinExistingModal = () => {
  const [leagueId, setLeagueId] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.getElementById("joinLeagueClose").click();
    try {
      const joinedLeague = await dispatch(joinLeague(leagueId, user));
      history.push(`/league/${joinedLeague.id}`);
    } catch (error) {
      console.error("Error joining league:", error);
    }
  };

  const handleChange = (e) => {
    setLeagueId(e.target.value);
  };

  return (
    <div className="container join-league-modal">
      <div
        className="modal fade add-league-modal__container"
        id="joinExistingLeagueModal"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Join Existing League</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="container join-league-modal__form">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="leagueId">League ID</label>
                  <input
                    className="form-control"
                    id="leagueId"
                    aria-describedby="leagueID"
                    placeholder="League Id"
                    onChange={handleChange}
                    value={leagueId}
                  />
                  <small className="form-text text-muted">
                    Enter the league id
                  </small>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <button
                id="joinLeagueClose"
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinExistingModal;
