import React, { Component } from "react";
import "./Terms.css";

class PrivacyNoticeModal extends Component {
  constructor(props) {
    super(props);
    this.state = { leagueId: "" };
    this.handleSubmit.bind(this);
    this.handleChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    document.getElementById("privacyNoticeModal").click();
    this.props.joinLeague(this.state.leagueId, this.props.user);
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value,
    });
  }
  render() {
    return (
      <div className="container terms-modal">
        <div
          className="modal fade add-league-modal__container"
          id="PrivacyNoticeModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Privacy Notice</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="container terms-modal__form">
                <div id="md_en" data-target="out_en" className="agreement_md">
                  <h3>Introduction</h3>
                  <p>
                    This is the privacy notice of Friends League. In this
                    document, "we", "our", or "us" refer to Friends League.
                  </p>
                  <p>
                    This privacy notice aims to inform you about how we collect
                    and process any information that we collect from you, or
                    that you provide to us. It covers information that could
                    identify you (“personal information”) and information that
                    could not. In the context of the law and this notice,
                    “process” means collect, store, transfer, use or otherwise
                    act on information. It tells you about your privacy rights
                    and how the law protects you.
                  </p>
                  <p>
                    We are committed to protecting your privacy and the
                    confidentiality of your personal information. Our policy is
                    not just an exercise in complying with the law, but a
                    continuation of our respect for you and your personal
                    information.
                  </p>
                  <p>
                    We undertake to preserve the confidentiality of all
                    information you provide to us, and hope that you
                    reciprocate.
                  </p>
                  <p>
                    Our policy complies with the Data Protection Act 2018 (Act)
                    accordingly incorporating the EU General Data Protection
                    Regulation (GDPR).
                  </p>
                  <p>
                    The law requires us to tell you about your rights and our
                    obligations to you in regard to the processing and control
                    of your personal data. We do this now, by requesting that
                    you read the information provided at
                    http://www.knowyourprivacyrights.org
                  </p>
                  <p>
                    Except as set out below, we do not share, or sell, or
                    disclose to a third party, any information collected through
                    our website.
                  </p>
                  <h3>
                    Information we process because we have a contractual
                    obligation with you
                  </h3>
                  <p>
                    When you create an account on our website, buy a product or
                    service from us, or otherwise agree to our terms and
                    conditions, a contract is formed between you and us.
                  </p>
                  <p>
                    In order to carry out our obligations under that contract we
                    must process the information you give us. Some of this
                    information may be personal information.
                  </p>
                  <p>We may use it in order to provide you with our services</p>
                  <p>
                    We process this information on the basis there is a contract
                    between us, or that you have requested we use the
                    information before we enter into a legal contract.
                  </p>
                  <p>
                    We shall continue to process this information until the
                    contract between us ends or is terminated by either party
                    under the terms of the contract.
                  </p>
                  <h3>Communicating with us</h3>
                  <p>
                    When you contact us, whether by telephone, through our
                    website or by e-mail, we collect the data you have given to
                    us in order to reply with the information you need.
                  </p>
                  <p>
                    We record your request and our reply in order to increase
                    the efficiency of Friends League.
                  </p>
                  <p>
                    We keep personally identifiable information associated with
                    your message, such as your name and email address so as to
                    be able to track our communications with you to provide a
                    high quality service.
                  </p>
                  <h3>Cookies</h3>
                  <p>
                    Cookies are small text files that are placed on your
                    computer's hard drive by your web browser when you visit any
                    website. They allow information gathered on one web page to
                    be stored until it is needed for use on another, allowing a
                    website to provide you with a personalised experience and
                    the website owner with statistics about how you use the
                    website so that it can be improved.
                  </p>
                  <p>
                    Some cookies may last for a defined period of time, such as
                    one day or until you close your browser. Others last
                    indefinitely.
                  </p>
                  <p>
                    Your web browser should allow you to delete any you choose.
                    It also should allow you to prevent or limit their use.
                  </p>
                  <p>
                    Our website uses cookies. They are placed by software that
                    operates on our servers, and by software operated by third
                    parties whose services we use.
                  </p>
                  <p>We use cookies to keep you signed in our site</p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  id="privacyNoticeModal"
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyNoticeModal;
